import React from "react";
import { navigate } from "@reach/router";
import { ReactComponent as HubspotLogo } from "../assets/images/logo-hubspot.svg";

import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import LoadingState from "./states/LoadingState";
import Config from "../config";
import Session from "./Session";
import Modal from "./modals/Modal";
import classNames from "../utils/classnames";
import fetchFromApi from "../utils/fetchFromApi";

class HubSpot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: true,
      connected: false,
      hubspot: "HubSpot",
      propertyStatus: false,
      propertySuccess: false,
      propertyError: false,
      propertyLoading: false,
      error: false,
    };
  }
  componentDidMount = async () => {
    document.title = `HubSpot ${Config.titleSuffix}`;

    const user = await Session.getUserData();
    if (!user?.org) {
      navigate("/choose-organisation");
      return;
    }

    await this.getStatus();
    this.setState({ pageLoading: false });
  };
  async getStatus() {
    try {
      const path = "/connections/hubspot/check";
      const response = await fetchFromApi(path, this.props.location.pathname);

      this.setState({
        ...response,
        pageLoading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  async disconnect() {
    try {
      const path = "/connections/hubspot/disconnect/";
      await fetchFromApi(path, this.props.location.pathname);
      this.getStatus();
    } catch (error) {
      console.error(error);
    }
  }

  async createProperties() {
    try {
      this.setState({ propertyLoading: true });
      const path = "/admin/properties/create";
      const response = await fetchFromApi(path, this.props.location.pathname);
      this.setState({
        propertyLoading: false,
        propertySuccess: true,
        properties: true,
      });
      console.log(response);
    } catch (error) {
      this.setState({ propertyLoading: false, propertyError: true });
      console.error(error);
    }
  }

  render() {
    return (
      <>
        {this.state.pageLoading ? (
          <LoadingState />
        ) : (
          <div
            className={classNames(
              this.props?.onboarding ? "my-20" : "w-full h-screen",
              "flex flex-col items-center justify-center",
            )}
          >
            <div className="p-16 mx-auto">
              <h2 className="mb-2">Add LinePilot to HubSpot</h2>
              <p>
                This gives LinePilot permissions to read and write data to your
                HubSpot account.
              </p>
              <div className="max-w-3xl mx-auto">
                <div className="flex-row flex-wrap items-center w-full gap-5 my-6 card">
                  <HubspotLogo />

                  {this.state.connected ? (
                    <div className="ml-4 -mt-2">
                      <span
                        className={classNames(
                          "text-sm font-medium text-green-500",
                          this.state.connectionError && "text-yellow-600",
                        )}
                      >
                        {this.state.connectionError && (
                          <ExclamationTriangleIcon className="inline w-4 h-4" />
                        )}
                        {this.state.connectionError
                          ? "Connection Error"
                          : "Connected"}
                      </span>
                      <h3 className="m-0">{this.state.hubspot}</h3>
                    </div>
                  ) : (
                    <div className="ml-4">
                      <h3>Not connected</h3>
                    </div>
                  )}

                  <div className="flex flex-row items-center flex-1 w-full gap-2 mt-4 text-right md:items-end md:mt-0 md:flex-col md:ml-5 ">
                    <div className="">
                      <a
                        className={classNames(
                          this.state.connected
                            ? "button-secondary"
                            : "button-primary",
                        )}
                        href={Config.api.root + "/connections/hubspot/connect"}
                      >
                        {this.state.connected ? "Reconnect" : "Start"}
                      </a>
                    </div>
                    {this.state.connected && (
                      <button
                        className="block text-xs text-right text-red-400 text-bold"
                        onClick={() => this.disconnect()}
                      >
                        Disconnect
                      </button>
                    )}
                  </div>
                </div>
                {this.props.onboarding && (
                  <div className="mt-4">
                    <button
                      onClick={() =>
                        this.state.connected && this.props.updateOnboarding(3)
                      }
                      disabled={!this.state.connected}
                      className="button-primary"
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
              <Modal
                open={this.state?.error}
                type="error"
                title={this.state.errorDetails?.title}
                message={this.state.errorDetails?.message}
                setClose={() => {
                  this.setState({ error: false }),
                    setTimeout(
                      () => this.setState({ errorDetails: false }),
                      500,
                    );
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default HubSpot;

import React from "react";
import { Link } from "@reach/router";
import LineItemPropertyInput from "./LineItemPropertyInput";
import { Bars3Icon, TrashIcon, WrenchIcon } from "@heroicons/react/24/solid";
import Select, { components } from "react-select";
import Config from "../config";
import { SparklesIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as ChooseIcon } from "../assets/images/icons8-choose.svg";

import { CubeTransparentIcon } from "@heroicons/react/20/solid";
import ProductSearch from "./fields/ProductSearch";
import { Draggable, Droppable } from "react-beautiful-dnd";

class LineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addActiveProperty: false,
      loading: true,
      productList: [],
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    // if productlookUp is empty update to be just an empty array
    if (!this.props.lookUpProducts) {
      await this.props.updateLineItem(this.props.id, "lookUpProducts", []);
    }

    // this._isMounted && (await this.getStatus());
    this.setState({ loading: false });
  };

  // async getStatus() {
  //   try {
  //     console.log("getting property data");
  //     let requestUrl = this.props?.lookupId && Config.api.root + "/lookup/get/" + this.props.lookupId;
  //     let response = await fetch(requestUrl, Config.api.options);
  //     if (response.status !== 200) {
  //       throw response.status;
  //     }
  //     response = await response.json();
  //     console.log(response);
  //     if (response.error) {
  //       throw response.error;
  //     }
  //     this._isMounted &&
  //       this.setState({
  //         ...response,
  //       });
  //   } catch (error) {
  //     this._isMounted &&
  //       this.setState({
  //         error: true,
  //         errorDetails: { title: "Could not fetch property", message: error },
  //         loading: false,
  //       });
  //   }
  // }

  async updateProperty(property, value = false, type = false) {
    this.setState({ selectedValue: null });
    if (!property) return false;

    let newSchema = { ...this.props.schema };
    newSchema[property] = {
      value: value,
      type: type || "property",
      hubspotDefined: !!this.props.lineItemPropertyDefinitions.find(
        (i) => i.name === property,
      )?.hubspotDefined,
    };

    await this.props.updateLineItem(this.props.id, "schema", newSchema);

    if (this.state.addActiveProperty) {
      this.setState({ addActiveProperty: false });
    }
  }

  async removeProperty(property) {
    let newSchema = { ...this.props.schema };
    delete newSchema[property];
    await this.props.updateLineItem(this.props.id, "schema", newSchema);
  }

  async handleSave() {
    await this.props.saveTemplate();
    await this.props.updateLineItem(this.props.id, "isEditing", false);
  }

  toNumber(number) {
    try {
      const formattedNumber = new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 10,
      }).format(number);

      if (isNaN(formattedNumber)) {
        throw new Error("Not a number");
      }
      return formattedNumber;
    } catch {
      return number;
    }
  }

  canUseCustomProperties = () => {
    return this.props?.account?.features?.customProperties;
  };

  render() {
    // remove null values from properties object
    // const productInfo = this.state.product.properties;

    const dataEntry =
      !this.state.loading &&
      Object.keys(this.props.schema).map((p, index) => {
        let schemaProperty = this.props.schema[p];
        let lineItemPropertyDefinition =
          this.props.lineItemPropertyDefinitions.find((i) => i.name === p);

        return (
          lineItemPropertyDefinition && (
            <LineItemPropertyInput
              data={schemaProperty}
              key={index}
              propertyDefinition={lineItemPropertyDefinition}
              dealPropertyDefinitions={this.props.dealPropertyDefinitions}
              updateProperty={this.updateProperty.bind(this)}
              removeProperty={this.removeProperty.bind(this)}
              required={false}
            />
          )
        );
      });

    const userCanUseCustomProperties = this.canUseCustomProperties();
    const hasCustomProperties =
      this.props.lineItemPropertyDefinitions.filter((p) => !p.hubspotDefined)
        .length > 0;
    const showProMessage =
      hasCustomProperties &&
      ["trial", "partner"].includes(this.props.user.org.plan);
    const showUpgradeMessage =
      hasCustomProperties &&
      ["free", "starter"].includes(this.props.user.org.plan);

    const CustomOption = (props) => {
      return (
        <components.Option {...props}>
          <div className="flex items-center">
            {props.children}
            {props.data.proIcon ? (
              <SparklesIcon className="w-6 h-6 p-1 ml-5 mr-2 text-white rounded-full bg-amber-500" />
            ) : null}
          </div>
        </components.Option>
      );
    };

    return (
      <div
        className={
          (this.props.isEditing && !this.state.loading && " flex-col p-0") +
          " card flex mb-2 "
        }
      >
        {!this.state.loading ? (
          <>
            {this.props.isEditing ? (
              <div className="flex flex-col divide-y">
                <div className="p-5">
                  <h3 className="flex items-center">
                    <Bars3Icon className="w-5 h-5 mr-3 -ml-3 text-stone-200" />
                    Edit Product Selector
                  </h3>
                </div>

                <div className="relative flex flex-row gap-4 px-10 py-5 items-top group">
                  <ChooseIcon className="absolute w-6 h-6 -ml-5 transition-all opacity-50 fill-primary group-hover:opacity-100" />
                  <div className="ml-5">
                    <h3>Set Up Your Product Selector</h3>
                    <p className="max-w-xl mt-2 text-sm text-tone-700">
                      A Product Selector is a deal property in HubSpot with a
                      list of products. When the template runs, the product the
                      user selects will be added to the deal.
                    </p>
                    <p className="max-w-xl mt-2 text-sm text-tone-700">
                      LinePilot will set the property up for you. You can set
                      permissions and control visibility for the property in
                      HubSpot, but come back to LinePilot to update the products
                      list.
                    </p>

                    <label className="flex items-center gap-5 my-4">
                      <span>Deal Property Label</span>
                      <input
                        type="text"
                        required
                        className="min-w-[300px] w-fit"
                        value={this.props.lookUpName}
                        onChange={(e) =>
                          this.props.updateLineItem(
                            this.props.id,
                            "lookUpName",
                            e.target.value,
                          )
                        }
                      />
                    </label>
                    <label className="pb-2">Product List</label>
                    <Droppable
                      droppableId={this.props.id.toString()}
                      type="product"
                    >
                      {(provided) => (
                        <div
                          className="w-full"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {this.props.lookUpProducts.map(
                            (product, itemIndex) => {
                              return (
                                <Draggable
                                  key={product.id}
                                  draggableId={product.id.toString()}
                                  index={itemIndex}
                                  type="product"
                                >
                                  {(provided) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="flex flex-row items-center justify-between w-full gap-4 py-2 bg-white border-b"
                                      >
                                        <div className="flex flex-row items-center">
                                          <Bars3Icon className="w-5 h-5 mr-4 text-stone-200" />
                                          <span className="text-xs text-tone-700 min-w-[100px]">
                                            {product.properties.hs_sku}
                                          </span>
                                          <span className="text-sm">
                                            {product.properties.name}
                                          </span>
                                        </div>
                                        <button
                                          onClick={() =>
                                            this.props.updateLineItem(
                                              this.props.id,
                                              "lookUpProducts",
                                              this.props.lookUpProducts.filter(
                                                (p) => p.id !== product.id,
                                              ),
                                            )
                                          }
                                        >
                                          <XMarkIcon className="w-4 h-4 text-red-600 " />
                                        </button>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            },
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <div className="py-2">
                      <ProductSearch
                        value={null}
                        location={this.props.location}
                        onChange={(o) => {
                          this.props.updateLineItem(
                            this.props.id,
                            "lookUpProducts",
                            [...this.props.lookUpProducts, o],
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-3 px-10 py-5 group">
                  <div className="flex flex-row items-center gap-4 -ml-5">
                    <CubeTransparentIcon className="w-6 h-6 transition-all opacity-50 text-primary group-hover:opacity-100" />
                    <div>
                      <h3>Properties</h3>
                      <p className="text-xs text-tone-700">
                        Set or override product properties for this line item.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-3 px-5">{dataEntry}</div>
                  <div className="relative flex flex-row flex-1 gap-4 px-5 items-left">
                    <Select
                      className="text-sm min-w-[200px] w-fit"
                      options={this.props.lineItemPropertyDefinitions
                        .map((p) => {
                          return {
                            label: p.label,
                            value: p.name,
                            isDisabled:
                              !p.hubspotDefined && !userCanUseCustomProperties,
                            proIcon:
                              !p.hubspotDefined &&
                              (showUpgradeMessage || showProMessage),
                          };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .filter((p) => !this.props.schema[p.value])}
                      isClearable="true"
                      placeholder="Add property"
                      theme={Config.selectTheme}
                      value={this.state.selectedValue}
                      onClick={() =>
                        this.setState({ openedPropertiesSelect: true })
                      }
                      onChange={(o) => this.updateProperty(o?.value)}
                      components={{
                        Option: (props) => {
                          return (
                            <CustomOption {...props}>
                              {props.children}
                            </CustomOption>
                          );
                        },
                      }}
                    />
                    {showUpgradeMessage && (
                      <Link
                        to="/billing"
                        className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group"
                      >
                        <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500 group-hover:bg-amber-600" />
                        <span className="text-xs">
                          Upgrade to unlock custom line item properties
                        </span>
                      </Link>
                    )}
                    {showProMessage && (
                      <div className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group">
                        <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500" />
                        <span className="text-xs leading-none">
                          Custom line item properties
                          <br /> are a pro feature
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row justify-end p-5">
                  <div className="flex gap-2">
                    <button
                      className="button-text"
                      onClick={() =>
                        this.props.updateLineItem(
                          this.props.id,
                          "isEditing",
                          false,
                        )
                      }
                    >
                      Close
                    </button>
                    <button
                      className="button-primary"
                      onClick={() => this.props.saveTemplate()}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative flex flex-row items-center flex-1 gap-5 pl-5">
                <div className="absolute -left-3">
                  <Bars3Icon className="w-5 h-5 text-stone-200 " />
                </div>

                <div className="flex flex-row items-center w-full gap-3">
                  {this.props.lookUpName} x{" "}
                  {this.props.schema?.quantity?.value || 0}
                </div>
                <div className="flex flex-shrink gap-2">
                  <button
                    className="button-icon"
                    onClick={() =>
                      this.props.updateLineItem(
                        this.props.id,
                        "isEditing",
                        true,
                      )
                    }
                  >
                    <WrenchIcon className="w-3 h-3" />
                  </button>

                  <button
                    className="button-icon"
                    onClick={() => this.props.removeLineItem(this.props.id)}
                  >
                    <TrashIcon className="w-3 h-3" />
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="">
            <h3 className="flex items-center">
              <Bars3Icon className="w-5 h-5 mr-3 -ml-3 text-stone-200" />
              Loading...
            </h3>
          </div>
        )}
      </div>
    );
  }
}

export default LineItem;

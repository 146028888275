import React from "react";
import { Link } from "@reach/router";
import LineItemPropertyInput from "./LineItemPropertyInput";
import {
  Bars3Icon,
  PlusIcon,
  TrashIcon,
  WrenchIcon,
} from "@heroicons/react/24/solid";
import Select, { components } from "react-select";
import Config from "../config";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { CubeTransparentIcon, EyeIcon } from "@heroicons/react/20/solid";
import ConditionSelector from "./ConditionSelector";
class LineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addActiveProperty: false,
    };
  }

  async updateProperty(property, value = false, type = false) {
    this.setState({ selectedValue: null });
    if (!property) return false;

    let newSchema = { ...this.props.schema };
    newSchema[property] = {
      value: value,
      type: type || "property",
      hubspotDefined: !!this.props.lineItemPropertyDefinitions.find(
        (i) => i.name === property,
      )?.hubspotDefined,
    };

    await this.props.updateLineItem(this.props.id, "schema", newSchema);

    if (this.state.addActiveProperty) {
      this.setState({ addActiveProperty: false });
    }
  }

  async removeProperty(property) {
    let newSchema = { ...this.props.schema };
    delete newSchema[property];
    await this.props.updateLineItem(this.props.id, "schema", newSchema);
  }

  async addCondition(type) {
    let conditions = this.props?.conditions || [];
    let id = Math.random().toString(36).substring(7);
    conditions.push({
      id: id,
      type,
      property: "",
      value: "",
      operator: "equals",
    });
    await this.props.updateLineItem(this.props.id, "conditions", conditions);
  }

  async updateCondition(id, property, value, operator) {
    let conditions = this.props?.conditions || [];
    conditions = conditions.map((c) => {
      if (c.id === id) {
        c.property = property;
        c.value = value;
        c.operator = operator;
      }
      return c;
    });

    await this.props.updateLineItem(this.props.id, "conditions", conditions);
  }
  async removeCondition(id) {
    let conditions = this.props?.conditions || [];
    conditions = conditions.filter((c) => c.id !== id);
    await this.props.updateLineItem(this.props.id, "conditions", conditions);
  }

  async handleSave() {
    await this.props.saveTemplate();
    await this.props.updateLineItem(this.props.id, "isEditing", false);
  }

  toNumber(number) {
    try {
      const formattedNumber = new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 10,
      }).format(number);

      if (isNaN(formattedNumber)) {
        throw new Error("Not a number");
      }
      return formattedNumber;
    } catch {
      return number;
    }
  }

  canUseCustomProperties = () => {
    return this.props?.account?.features?.customProperties;
  };

  render() {
    const dataEntry = Object.keys(this.props.schema).map((p, index) => {
      let schemaProperty = this.props.schema[p];
      let lineItemPropertyDefinition =
        this.props.lineItemPropertyDefinitions.find((i) => i.name === p);
      return (
        lineItemPropertyDefinition && (
          <LineItemPropertyInput
            data={schemaProperty}
            key={index}
            propertyDefinition={lineItemPropertyDefinition}
            dealPropertyDefinitions={this.props.dealPropertyDefinitions}
            updateProperty={this.updateProperty.bind(this)}
            removeProperty={this.removeProperty.bind(this)}
            required={["name", "price", "quantity"].includes(
              lineItemPropertyDefinition?.name,
            )}
          />
        )
      );
    });

    const userCanUseCustomProperties = this.canUseCustomProperties();
    const hasCustomProperties =
      this.props.lineItemPropertyDefinitions.filter((p) => !p.hubspotDefined)
        .length > 0;
    const showProMessage =
      hasCustomProperties &&
      ["trial", "partner"].includes(this.props.user.org.plan);
    const showUpgradeMessage =
      hasCustomProperties &&
      ["free", "starter"].includes(this.props.user.org.plan);

    const CustomOption = (props) => {
      return (
        <components.Option {...props}>
          <div className="flex items-center w-full">
            <span className="flex-1">{props.children}</span>
            {props.data.proIcon && (
              <SparklesIcon className="w-6 h-6 p-1 ml-5 mr-2 text-white rounded-full bg-amber-500 min-w-fit" />
            )}
          </div>
        </components.Option>
      );
    };

    return (
      <div
        className={
          (this.props.isEditing && " flex-col p-0") + " card flex mb-2 "
        }
      >
        {this.props.isEditing ? (
          <div className="flex flex-col divide-y">
            <div className="p-5 ">
              <h3 className="flex items-center">
                <Bars3Icon className="w-5 h-5 mr-3 -ml-3 text-stone-200" />
                Edit Line Item
              </h3>
            </div>

            <div className="flex flex-col gap-3 px-10 py-5 group">
              <div className="flex flex-row items-center gap-4 mb-2 -ml-5">
                <CubeTransparentIcon className="w-6 h-6 transition-all opacity-50 text-primary group-hover:opacity-100" />
                <div>
                  <h3>Properties</h3>
                  <p className="text-xs text-tone-700">
                    Set the following properties on the line item...
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-3 px-5">{dataEntry}</div>
              <div className="relative flex flex-row flex-1 gap-2 px-5 items-left">
                <Select
                  className="text-sm min-w-[200px] w-fit"
                  options={this.props.lineItemPropertyDefinitions
                    .map((p) => {
                      return {
                        label: p.label,
                        value: p.name,
                        isDisabled:
                          !p.hubspotDefined && !userCanUseCustomProperties,
                        proIcon:
                          !p.hubspotDefined &&
                          (showUpgradeMessage || showProMessage),
                      };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .filter((p) => !this.props.schema[p.value])}
                  isClearable="true"
                  placeholder="Add property"
                  theme={Config.selectTheme}
                  value={this.state.selectedValue}
                  onClick={() =>
                    this.setState({ openedPropertiesSelect: true })
                  }
                  onChange={(o) => this.updateProperty(o?.value)}
                  components={{
                    Option: (props) => {
                      return (
                        <CustomOption {...props}>{props.children}</CustomOption>
                      );
                    },
                  }}
                />
                {showUpgradeMessage && (
                  <Link
                    to="/billing"
                    className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group"
                  >
                    <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500 group-hover:bg-amber-600" />
                    <span className="text-xs">
                      Upgrade to unlock custom line item properties
                    </span>
                  </Link>
                )}
                {showProMessage && (
                  <div className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group">
                    <SparklesIcon className="w-6 h-6 p-1 mr-2 leading-snug text-white transition-colors rounded-full bg-amber-500" />
                    <span className="text-xs">
                      Custom line item properties
                      <br />
                      are a pro feature
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="px-10 py-5 group">
              <div className="flex flex-row items-center gap-4 mb-2 -ml-5 ">
                <EyeIcon className="w-6 h-6 transition-all opacity-50 text-primary group-hover:opacity-100" />
                <div>
                  <h3>Conditions</h3>
                  <p className="text-xs text-tone-700">
                    Only show this line item if the following conditions are
                    met...
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-1 px-5 mt-2">
                {this.props?.conditions &&
                  this.props.conditions.map((f, index) => {
                    return (
                      <ConditionSelector
                        data={f}
                        key={index}
                        index={index}
                        dealPropertyDefinitions={
                          this.props.dealPropertyDefinitions
                        }
                        lineItemPropertyDefinitions={
                          this.props.lineItemPropertyDefinitions
                        }
                        dealStages={this.props.dealStages}
                        updateFilter={this.updateCondition.bind(this)}
                        removeFilter={this.removeCondition.bind(this)}
                      />
                    );
                  })}
              </div>
              <div className="text-center">
                <button
                  className="mt-2 text-sm button-low text-primary hover:text-primary-400"
                  onClick={() => {
                    this.addCondition("deal");
                  }}
                >
                  <PlusIcon className="inline w-4 mr-1" />
                  Add Deal Condition
                </button>

                <button
                  className="mt-2 text-sm button-low text-primary hover:text-primary-400"
                  onClick={() => {
                    this.addCondition("line");
                  }}
                >
                  <PlusIcon className="inline w-4 mr-1" />
                  Add Line Item Condition
                </button>
              </div>
            </div>

            <div className="flex flex-row justify-end p-5">
              <div className="flex gap-2">
                <button
                  className="button-text"
                  onClick={() =>
                    this.props.updateLineItem(this.props.id, "isEditing", false)
                  }
                >
                  Close
                </button>
                <button
                  className="button-primary"
                  onClick={() => this.props.saveTemplate()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative flex flex-row items-center flex-1 gap-5 pl-5">
            <div className="absolute -left-3">
              <Bars3Icon className="w-5 h-5 text-stone-200 " />
            </div>
            {this.props.schema?.hs_sku?.value && (
              <div className="text-xs text-toneDark">
                {this.props.schema?.hs_sku?.value}
              </div>
            )}
            <h3 className="flex-grow">
              {this.props.schema?.name?.value || "New Line Item"}{" "}
              <span className="text-xs text-toneDark">
                {" "}
                x {this.props.schema?.quantity?.value || 0}
              </span>
            </h3>
            {this.props.schema?.price?.value && (
              <div className="text-xs text-toneDark">
                {this.toNumber(this.props.schema?.price?.value)}
              </div>
            )}
            <div className="flex flex-shrink gap-2">
              <button
                className="button-icon"
                onClick={() =>
                  this.props.updateLineItem(this.props.id, "isEditing", true)
                }
              >
                <WrenchIcon className="w-3 h-3" />
              </button>
              <button
                className="button-icon"
                onClick={() => this.props.removeLineItem(this.props.id)}
              >
                <TrashIcon className="w-3 h-3" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LineItem;
